import { Button } from "antd";
import { useLazyQuery } from "@apollo/client";

import { TEST_GENERAL } from "../../GraphQLQueries/userQueries";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import serverhost from "../../config.json";
import axios from "axios";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import { setWBDGRParams } from "../../store/store";

const baseURLServ = serverhost.serverhost;

function TestReportsButtons({ checkedTrips, setLoadingState }) {
  // const wbdGRParams = useSelector((state) => state.user.wbdGRParams);
  // const dispatch = useDispatch();

  // if (!wbdGRParams) {
  //   var utc = require("dayjs/plugin/utc");
  //   dayjs.extend(utc);

  //   if (!wbdGRParams)
  //     dispatch(
  //       setWBDGRParams({
  //         maxAveragePointTime: dayjs.utc("1970-01-01 00:25:00").toString(),
  //         maxAverageWarehouseTime: dayjs.utc("1970-01-01 01:15:00").toString(),
  //         minTripDuration: dayjs.utc("1970-01-01 07:00:00").toString(),
  //         maxTripDuration: dayjs.utc("1970-01-01 08:15:00").toString(),
  //         deliveryTipe: ["allDelivery"],
  //       })
  //     );
  // }

  // const [
  //   testGeneal,
  //   { loading: testLoading, error: testError, data: testData },
  // ] = useLazyQuery(TEST_GENERAL);

  // useEffect(() => {
  //   if (testLoading) setLoadingState(true);
  //   else if (testError) {
  //     alert("Ошибка формирования отчета!");
  //     setLoadingState(false);
  //   } else {
  //     if (testData) {
  //       const fileName = testData.createGeneralMonitoringReport;
  //       const urlStr = baseURLServ + "/reports/" + fileName;
  //       axios
  //         .get(urlStr, {
  //           withCredentials: true,
  //           responseType: "blob",
  //         })
  //         .then((res) => {
  //           fileDownload(res.data, fileName);
  //           setLoadingState(false);
  //           // setLoadedReport(true);
  //         })
  //         .catch((error) => {
  //           setLoadingState(false);
  //           // setLoadedReport(true);
  //           console.log("fileDownload error: ", error);
  //           alert("Ошибка загрузки файла!");
  //         });
  //     }
  //   }
  // }, [testLoading, testError, testData]);

  return (
    <div>
      <Button
        disabled={checkedTrips.length === 0 ? true : false}
        onClick={() => {
          // console.log("CheckedTrips: ", checkedTrips);
          // testGeneal({
          //   fetchPolicy: "no-cache",
          //   variables: { tripIds: checkedTrips },
          // });

          // const wbdparams = WBD_GR_REPORT_PARAMS(wbdGRParams);
          // console.log("wbdparams ", wbdparams);

          axios
            .post(
              `${baseURLServ}/wbd/testgeneralreport`,
              {
                tripsIds: checkedTrips,
                // groupingType: groupingType[0],
                // deliveryType: wbdparams.deliveryType,
                // maxAverageWarehouseTime: wbdparams.maxAverageWarehouseTime,
                // minTripDuration: wbdparams.minTripDuration,
                // maxTripDuration: wbdparams.maxTripDuration,
                // maxAveragePointTime: wbdparams.maxAveragePointTime,
              },
              {
                withCredentials: true,
                responseType: "json",
              }
            )
            .then((resp) => {
              // console.log("Resp: ", resp);
              const urlGRFile = baseURLServ + "/reports/" + resp.data;
              const grFileName = resp.data;
              axios
                .get(urlGRFile, {
                  withCredentials: true,
                  responseType: "blob",
                })
                .then((resp) => {
                  fileDownload(resp.data, grFileName);
                  setLoadingState(false);
                })
                .catch((err) => {
                  alert("Ошибка загрузки файла!");
                  console.log("Ошибка загрузки файла: ", err);
                  setLoadingState(false);
                });
            })
            .catch((err) => {
              setLoadingState(false);
              console.log("Err: ", err);
            });
        }}
        variant="outlined"
        size="small"
      >
        Тест
      </Button>
    </div>
  );
}

export default TestReportsButtons;
