import { Box, Typography } from "@mui/material";
import { Input } from "antd";
import React from "react";
import WBDGRParams from "./WBDGRParams";
import WBDMRParams from "./WBDMRParams";

function WBDMRParamsPane() {
  return (
    <Box display="flex" flexDirection="column" padding="5px">
      <WBDMRParams />
      <WBDGRParams />
    </Box>
  );
}

export default WBDMRParamsPane;
