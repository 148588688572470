import { Box } from "@mui/material";
import { Radio, Button, Cascader, Spin } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import DataTable from "../DataTable";
import {
  CREATE_MONITORING_REPORT,
  TEST_GENERAL,
} from "../../GraphQLQueries/userQueries";
import ReportsLoadButtons from "./ReportsLoadButtons";
import { Vrpano } from "@mui/icons-material";

function WBDBottomPanel({
  tripsList,
  // tripsError,
  tripsLoading,
  reportType,
  groupingType,
}) {
  const [checkedTrips, setChekedTrips] = useState([]);
  const [validRows, setvalidRows] = useState([]);
  const [unvalidRows, setunvalidRows] = useState([]);
  const [allTrips, setAllTrips] = useState([]);
  const [panelSelected, setPanelSelected] = useState("valid");
  const [loadingState, setLoadingState] = useState(false);
  // const [loadedReport, setLoadedReport] = useState(true);

  const [
    testGeneal,
    { loading: testLoading, error: testError, data: testData },
  ] = useLazyQuery(TEST_GENERAL);

  useEffect(() => {
    //Идет загрузка списка рейсов
    if (tripsLoading) {
      setLoadingState(tripsLoading);
      // console.log("WBDBottomPanel loading trips: ", tripsLoading);
      setvalidRows([]);
      setunvalidRows([]);
    }
    // Произошла ошибка при загрузке списка рейсов
    // else if (tripsError) {
    //   setLoadingState(tripsLoading);

    //   console.log("WBDBottomPanel loading trips: ", tripsLoading);
    // }
    else {
      setLoadingState(tripsLoading);

      if (tripsList) {
        // console.log("WBDBottomPanel: tripsList: ", tripsList);

        setAllTrips(tripsList.map((item) => item.tripID));

        const vr = tripsList
          .filter(
            (item) =>
              item.totalPointsCount > 0 &&
              item.totalStatusPoints > 0 &&
              item.stateNumber !== null
          )
          .map((item) => ({
            Number: item.number,
            StateNumber: item.stateNumber,
            TotalPointsCount: item.totalPointsCount,
            TotalStatusPoints: item.totalStatusPoints,
            TripID: item.tripID,
            ShiftDate: item.shiftDate,
          }));

        // console.log("WBDBottomPanel: vr: ", vr);

        setvalidRows(vr);

        setunvalidRows(
          tripsList
            .filter(
              (item) =>
                item.totalPointsCount === 0 ||
                item.totalStatusPoints === 0 ||
                item.stateNumber === null
            )
            .map((item) => ({
              Number: item.number,
              StateNumber: item.stateNumber,
              TotalPointsCount: item.totalPointsCount,
              TotalStatusPoints: item.totalStatusPoints,
              TripID: item.tripID,
              ShiftDate: item.shiftDate,
            }))
        );
      }
    }
  }, [tripsList, tripsLoading]);

  // console.log("WBDBottomPanel: validRows: ", validRows);
  // console.log("WBDBottomPanel: unvalidRows: ", unvalidRows);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          height: "38px",
          paddingTop: "6px",
          display: "flex",
          flexDirection: "row",
          flexShrink: "0",
          flexBasis: "38px",
          padding: "2px",
        }}
      >
        <Radio.Group
          defaultValue={panelSelected}
          buttonStyle="solid"
          onChange={(event) => {
            setPanelSelected(event.target.value);
          }}
          size="small"
          style={{ flexGrow: "1" }}
        >
          <Radio.Button value="valid">Корректные</Radio.Button>
          <Radio.Button value="unvalid">Некорректные</Radio.Button>
        </Radio.Group>
        <ReportsLoadButtons
          checkedTrips={checkedTrips}
          reportType={reportType}
          setLoadingState={setLoadingState}
          groupingType={groupingType}
          allTrips={allTrips}
        />
      </Box>
      <Box
        flexGrow={1}
        sx={{ backgroundColor: "whitesmoke" }}
        overflow={"auto"}
      >
        {/* <AntdTable /> */}
        {loadingState ? (
          <Spin
            size="large"
            style={{ position: "absolute", zIndex: "99", marginTop: "15%" }}
          />
        ) : null}
        {panelSelected === "valid" ? (
          <DataTable
            setCheckedTrips={setChekedTrips}
            rows={validRows}
            loadingState={loadingState}
            checkedTrips={checkedTrips}
          />
        ) : null}
        {panelSelected === "unvalid" ? (
          <DataTable
            setCheckedTrips={setChekedTrips}
            rows={unvalidRows}
            loadingState={loadingState}
            checkedTrips={checkedTrips}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          height: "1px",
          backgroundColor: "whitesmoke",
          flexBasis: "1px",
          flexShrink: "0",
        }}
      ></Box>
    </Box>
  );
}

export default WBDBottomPanel;
