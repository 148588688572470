import { Box, Typography } from "@mui/material";
import { Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWBDMRPapams } from "../../store/store";

function WBDMRParams() {
  const wbdMRParams = useSelector((state) => state.user.wbdMRParams);
  const dispatch = useDispatch();
  if (!wbdMRParams)
    dispatch(
      setWBDMRPapams({ minStopTime: 3, shiftToStart: -2, shiftToEnd: 4 })
    );

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "8px",
        borderColor: "#1976d2",
      }}
      padding="5px"
      mb="5px"
    >
      <Box display="flex" flexDirection="row" mb="4px">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Мин. время в ТТ (мин.)
        </Typography>
        <Input
          defaultValue={wbdMRParams.minStopTime}
          placeholder="3"
          size="small"
          onChange={(event) =>
            dispatch(
              setWBDMRPapams({
                ...wbdMRParams,
                minStopTime: Number(event.target.value),
              })
            )
          }
          style={{ width: "70px" }}
        />
      </Box>

      <Box display="inline-flex" mb="4px">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Сдвиг начала рейса (ч)
        </Typography>
        <Input
          defaultValue={wbdMRParams.shiftToStart}
          placeholder="3"
          size="small"
          onChange={(event) =>
            dispatch(
              setWBDMRPapams({
                ...wbdMRParams,
                shiftToStart: Number(event.target.value),
              })
            )
          }
          style={{ width: "70px" }}
        />
      </Box>

      <Box display="inline-flex">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Сдвиг окончания рейса (ч)
        </Typography>
        <Input
          defaultValue={wbdMRParams.shiftToEnd}
          placeholder="3"
          size="small"
          onChange={(event) =>
            dispatch(
              setWBDMRPapams({
                ...wbdMRParams,
                shiftToEnd: Number(event.target.value),
              })
            )
          }
          style={{ width: "70px" }}
        />
      </Box>
    </Box>
  );
}

export default WBDMRParams;
