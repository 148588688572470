import { Box } from "@mui/material";
import "react-data-grid/lib/styles.css";
import DataGrid, { SelectColumn } from "react-data-grid";
import React, { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { useLazyQuery } from "@apollo/client";
import { GET_TRIP } from "../GraphQLQueries/userQueries";
import { Spin } from "antd";

const columns = [
  // { key: "TripID", name: "ID" },
  SelectColumn,
  { key: "Number", name: "№ рейса", resizable: true, frozen: true },
  { key: "ShiftDate", name: "Дата", resizable: true, frozen: true },
  { key: "StateNumber", name: "ГНЗ", resizable: true, frozen: true },
  {
    key: "TotalPointsCount",
    name: "Т",
    resizable: false,
    frozen: true,
    minWidth: 30,
    width: 40,
  },
  {
    key: "TotalStatusPoints",
    name: "П",
    resizable: false,
    minWidth: 30,
    frozen: true,
    width: 40,
  },
];

const rowKeyGetter = (row) => {
  // console.log("Row: ", row);
  return row.TripID;
};

//
function DataTable({ rows, setCheckedTrips, loadingState, checkedTrips }) {
  const [dataRows, setDataRows] = useState(rows);
  const [selectedRows, setSelectedRows] = useState(new Set());
  // const [getTrip, { loading, error, data }] = useLazyQuery(GET_TRIP);

  // console.log("Selected rows: ", [...selectedRows]);

  const selectRowsChange = (target) => {
    console.log("target: ", [target]);
    // console.log("checkedTrips: ", checkedTrips);
    // console.log("All: ", [...checkedTrips, ...target]);

    setCheckedTrips([...target]);
    setSelectedRows(target);
  };

  // if (error) console.log("Get trip error: ", error);
  // else if (loading) console.log("Get trip loading...");
  // else {
  //   console.log("Get trip data: ", data);
  // }

  useEffect(() => {
    // console.log("DT useEffect rows: ", rows);
    setSelectedRows(new Set());
    setCheckedTrips([]);
    setDataRows(rows);
  }, [rows]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box flexGrow={1} overflow={"auto"}>
        {loadingState ? (
          <Spin
            size="large"
            style={{ position: "absolute", zIndex: "99", marginTop: "15%" }}
          />
        ) : null}
        <DataGrid
          rowHeight={27}
          headerRowHeight={27}
          rowKeyGetter={rowKeyGetter}
          columns={columns}
          rows={dataRows}
          // rows={[]}
          style={{ height: "100%" }}
          selectedRows={selectedRows}
          onSelectedRowsChange={selectRowsChange}
          // onCellDoubleClick={(args) => {
          //   console.log("args: ", args.row.TripID);
          //   getTrip({ variables: { tripId: args.row.TripID } });
          //   // console.log("event: ", event);
          // }}
        />
      </Box>
      <Box sx={{ height: "1px", flexBasis: "1px", flexShrink: "0" }}></Box>
    </Box>
  );
}

export default DataTable;
