import React, { useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  faChevronDown,
  faChevronRight,
  faFolderClosed,
  faFolderOpen,
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";

const nodes = [
  {
    value: "mars",
    label: "Mars",
    children: [
      {
        value: "phobos",
        label: "Phobos",
        children: [
          { value: "test1", label: "test1" },
          { value: "test2", label: "test2" },
        ],
      },
      { value: "deimos", label: "Deimos" },
    ],
  },
  {
    value: "test3",
    label: "Test3",
  },
  {
    value: "test4",
    label: "Test4",
  },
];

function CheckBoxTreeView({ treeData, setCheckedIds }) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  // console.log("DataTree in checkBoxTree: ", treeData);

  return (
    <CheckboxTree
      icons={{
        check: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-check"
            icon={faSquareCheck}
          />
        ),
        uncheck: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-uncheck"
            icon={faSquare}
          />
        ),
        halfCheck: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-half-check"
            icon={faSquareXmark}
          />
        ),
        expandClose: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-expand-close"
            icon={faChevronRight}
          />
        ),
        expandOpen: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-expand-open"
            icon={faChevronDown}
          />
        ),
        expandAll: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-expand-all"
            icon={faSquarePlus}
          />
        ),
        collapseAll: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-collapse-all"
            icon={faSquareMinus}
          />
        ),
        parentClose: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-parent-close"
            icon={faFolderClosed}
          />
        ),
        parentOpen: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-parent-open"
            icon={faFolderOpen}
          />
        ),
        leaf: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-leaf-close"
            icon={faFile}
          />
        ),
      }}
      nodes={treeData ? treeData : []}
      // nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={(checked) => {
        setChecked(checked);
        // console.log("Checked: ", checked);
        setCheckedIds(checked);
      }}
      onExpand={(expanded) => setExpanded(expanded)}
    />
  );
}

export default CheckBoxTreeView;
