import React from "react";
import Header from "../components/Header";

function MainPage() {
  return (
    <>
      <Header />

      <h1>Main Page</h1>
    </>
  );
}

export default MainPage;
