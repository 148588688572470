import { gql } from "@apollo/client";

const loginQuery = gql`
  query connect($login: String!, $pass: String!) {
    connectToAPI(login: $login, password: $pass) {
      username
      email
      description
      connectStatus
    }
  }
`;

// export const getObjectsTree = gql`
//     query getObjectsTree($all:Boolean!){
//         getTreeFromAPI(all:$all){
//             companiesList{
//                 id
//                 name
//             }
//         }
//     }
// `

export const getObjectsTree = gql`
  query getObjectsTree($all: Boolean!) {
    getTreeFromAPI(all: $all)
  }
`;

export const GET_TRIPS = gql`
  query getTrips(
    $objIds: [Int]!
    $dtStart: String!
    $dtEnd: String!
    $timeZone: Int!
  ) {
    getTrips(
      objIds: $objIds
      dateStart: $dtStart
      dateEnd: $dtEnd
      timeZone: $timeZone
    ) {
      TripID
      Number
      ShiftDate
      StateNumber
      TotalPointsCount
      TotalStatusPoints
    }
  }
`;

export const GET_TRIP = gql`
  query getTripById($tripId: Int!) {
    getTripFM3ById(id: $tripId) {
      Number
      ShiftDate
      StartDate
      EndDate
      StateNumber
      TotalPointsCount
      TotalStatusPoints
      Mileage
      TotalWeight
      TotalBoxes
    }
  }
`;

export const CREATE_MONITORING_REPORT = gql`
  query createMonitoringReport($tripIds: [Int]!) {
    createMonitoringReport(tripIDs: $tripIds)
  }
`;

export const TEST_GENERAL = gql`
  query createGeneralMonitoringReport($tripIds: [Int]!) {
    createGeneralMonitoringReport(tripIDs: $tripIds)
  }
`;

export default loginQuery;
