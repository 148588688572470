import { Cascader, TimePicker } from "antd";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setWBDGRParams } from "../../store/store";

const deliveryTypeItems = [
  {
    value: "cityDelivery",
    label: "Городская",
  },
  {
    value: "intercityDelivery",
    label: "Областная",
  },
  {
    value: "joinDelivery",
    label: "Совместная",
  },
  {
    value: "specialDelivery",
    label: "Специальная",
  },
  {
    value: "allDelivery",
    label: "Все",
  },
];

function WBDGRParams() {
  const wbdGRParams = useSelector((state) => state.user.wbdGRParams);
  const dispatch = useDispatch();

  if (!wbdGRParams) {
    var utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);

    dispatch(
      setWBDGRParams({
        maxAveragePointTime: dayjs.utc("1970-01-01 00:25:00").toString(),
        maxAverageWarehouseTime: dayjs.utc("1970-01-01 01:15:00").toString(),
        minTripDuration: dayjs.utc("1970-01-01 07:00:00").toString(),
        maxTripDuration: dayjs.utc("1970-01-01 08:15:00").toString(),
        deliveryTipe: ["allDelivery"],
      })
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "8px",
        borderColor: "#1976d2",
      }}
      padding="5px"
    >
      <Box display="inline-flex" mb="4px">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Среднее время в ТТ (более)
        </Typography>
        <TimePicker
          size="small"
          allowClear={false}
          defaultValue={dayjs.utc(wbdGRParams.maxAveragePointTime)}
          style={{
            width: "85px",
          }}
          onChange={(value) =>
            dispatch(
              setWBDGRParams({
                ...wbdGRParams,
                maxAveragePointTime: dayjs
                  .utc("1970-01-01 00:00:00")
                  .set("hour", value.hour())
                  .set("minute", value.minute())
                  .set("second", value.second())
                  .toString(),
              })
            )
          }
        />
      </Box>

      <Box display="inline-flex" mb="4px">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Среднее время на складе (более)
        </Typography>
        <TimePicker
          size="small"
          allowClear={false}
          defaultValue={dayjs.utc(wbdGRParams.maxAverageWarehouseTime)}
          style={{
            width: "85px",
          }}
          onChange={(value) =>
            dispatch(
              setWBDGRParams({
                ...wbdGRParams,
                maxAverageWarehouseTime: dayjs
                  .utc("1970-01-01 00:00:00")
                  .set("hour", value.hour())
                  .set("minute", value.minute())
                  .set("second", value.second())
                  .toString(),
              })
            )
          }
        />
      </Box>

      <Box display="inline-flex" mb="4px">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Среднее время рейса{" "}
        </Typography>
        <Typography variant="body2" flexGrow={1} textAlign="right" pr="3px">
          от
        </Typography>
        <TimePicker
          size="small"
          allowClear={false}
          defaultValue={dayjs.utc(wbdGRParams.minTripDuration)}
          style={{
            width: "85px",
          }}
          onChange={(value) =>
            dispatch(
              setWBDGRParams({
                ...wbdGRParams,
                minTripDuration: dayjs
                  .utc("1970-01-01 00:00:00")
                  .set("hour", value.hour())
                  .set("minute", value.minute())
                  .set("second", value.second())
                  .toString(),
              })
            )
          }
        />
        <Typography variant="body2" pr="3px" pl="3px">
          до{" "}
        </Typography>
        <TimePicker
          size="small"
          allowClear={false}
          defaultValue={dayjs.utc(wbdGRParams.maxTripDuration)}
          style={{
            width: "85px",
          }}
          onChange={(value) =>
            dispatch(
              setWBDGRParams({
                ...wbdGRParams,
                maxTripDuration: dayjs
                  .utc("1970-01-01 00:00:00")
                  .set("hour", value.hour())
                  .set("minute", value.minute())
                  .set("second", value.second())
                  .toString(),
              })
            )
          }
        />
      </Box>
      <Box display="inline-flex">
        <Typography variant="body2" flexGrow={1} textAlign="left">
          Тип доставки
        </Typography>
        <Cascader
          size="small"
          options={deliveryTypeItems}
          allowClear={false}
          defaultValue={wbdGRParams.deliveryTipe}
          onChange={(value) =>
            dispatch(
              setWBDGRParams({
                ...wbdGRParams,
                deliveryTipe: value,
              })
            )
          }
          style={{
            width: "192px",
          }}
        />
      </Box>
    </Box>
  );
}

export default WBDGRParams;
