import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/MainPage";
import MonitoringReport from "./pages/MonitoringReport";
import GeneralMonitoringReport from "./pages/GeneralMonitoringReport";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound";
import MainLayout from "./pages/MainLayout";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import axios from "axios";
import serverhost from "./config.json";

const redirectToLogin = (
  <Navigate to="/login" replace={false} state={{ from: "login2" }} />
);

const baseUrlServ = serverhost.serverhost;

const instAxios = axios.create({
  baseURL: baseUrlServ,
  withCredentials: true,
});

console.log("baseUrlServ: ", baseUrlServ);

const clientApollo = new ApolloClient({
  uri: baseUrlServ + "/graphql",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={clientApollo}>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="login" element={<LoginPage />} />
            <Route path="/" element={<MainLayout />}>
              <Route index element={<MainPage />} />
              <Route path="monitoringreport" element={<MonitoringReport />} />
              <Route
                path="generalmonitoringreport"
                element={<GeneralMonitoringReport />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
