import fileDownload from "js-file-download";
import axios from "axios";

export const DOWNLOAD_REPORT_FILE = (
  baseURLServ,
  fileName,
  setLoadingState
) => {
  const urlGRFile = baseURLServ + "/reports/" + fileName;
  // const grFileName = resp.data;
  axios
    .get(urlGRFile, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((resp) => {
      fileDownload(resp.data, fileName);
      setLoadingState(false);
    })
    .catch((err) => {
      alert("Ошибка загрузки файла!");
      console.log("Ошибка загрузки файла: ", err);
      setLoadingState(false);
    });
};
