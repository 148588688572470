import {
  Space,
  DatePicker,
  Button,
  Input,
  Cascader,
  ConfigProvider,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import locale from "antd/locale/ru_RU";
import { useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import serverhost from "../config.json";
import { GP } from "../Consts/Consts";
import { useSelector } from "react-redux";
import axios from "axios";
import fileDownload from "js-file-download";

const { RangePicker } = DatePicker;

const baseURLServ = serverhost.serverhost;

const groupingItems = [
  {
    value: GP.NONE,
    picker: "date",
    label: "За период",
  },
  {
    value: GP.WEEK,
    picker: "week",
    label: "Неделя",
  },
  {
    value: GP.MONTH,
    picker: "month",
    label: "Месяц",
  },
  {
    value: GP.QUARTER,
    picker: "quarter",
    label: "Квартал",
  },
  {
    value: GP.YEAR,
    picker: "year",
    label: "Год",
  },
];

function DTField({
  loadTrips,
  checkedIds,
  reportType,
  groupingType,
  setGroupingType,
}) {
  const dateNow = new Date();
  // const yesterdayDate = new Date(
  //   dateNow.getFullYear(),
  //   dateNow.getMonth(),
  //   dateNow.getDate() - 1
  // );

  const dateStart = dayjs()
    .add(-1, "day")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0);

  const dateEnd = dayjs()
    .add(-1, "day")
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59);

  const [tripNumber, setTripNumber] = useState("");
  const [pickerValue, setPickerValue] = useState(groupingItems[0].picker);
  const [dateRange, setDateRange] = useState({
    start: dateStart,
    end: dateEnd,
  });

  useEffect(() => {
    // console.log("groupingType: ", groupingType);
    setPickerValue(groupingItems[groupingType[0]].picker);
  }, [groupingType]);

  return (
    <Space direction="horizontal" size={5}>
      {reportType[0] === "generalMR" ? (
        <Cascader
          size="small"
          defaultValue={groupingType}
          options={groupingItems}
          allowClear={false}
          onChange={(value) => {
            // console.log("groupingType: ", groupingType);
            setPickerValue(groupingItems[value[0]].picker);
            setGroupingType(value);
          }}
          style={{ width: "120px" }}
        />
      ) : null}

      <ConfigProvider locale={locale}>
        <RangePicker
          size="small"
          picker={pickerValue}
          defaultValue={[dayjs(dateStart), dayjs(dateEnd)]}
          onChange={(newValue) => {
            if (newValue) {
              // console.log("DateRange: ", typeof newValue[0]);

              switch (pickerValue) {
                case groupingItems[0].picker:
                  {
                    // console.log("Дата");
                    const fd = newValue[0]
                      // .day(1)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0);
                    const ld = newValue[1]
                      // .day(7)
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("second", 59);
                    setDateRange({
                      start: fd,
                      end: ld,

                      // start: fd.toDate(),
                      // end: ld.toDate(),
                    });
                  }
                  break;
                case groupingItems[1].picker:
                  {
                    const fd = newValue[0]
                      .day(1)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0);
                    const ld = newValue[1]
                      .day(7)
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("second", 59);

                    setDateRange({
                      start: fd,
                      end: ld,
                      // start: fd.toDate(),
                      // end: ld.toDate(),
                    });
                  }
                  break;
                case groupingItems[2].picker:
                  {
                    // console.log("Месяц");
                    const fd = newValue[0]
                      .date(1)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0);
                    const ld = newValue[1]
                      .add(1, "month")
                      .date(0)
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("second", 59);

                    setDateRange({
                      start: fd,
                      end: ld,

                      // start: fd.toDate(),
                      // end: ld.toDate(),
                    });
                  }
                  break;
                case groupingItems[3].picker:
                  {
                    // console.log("Квартал");

                    const fd = newValue[0]
                      // .date(1)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0);
                    const ld = newValue[1]
                      .add(3, "month")
                      .date(0)
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("second", 59);

                    // console.log("fd:", fd);
                    // console.log("ld:", ld);

                    setDateRange({
                      start: fd,
                      end: ld,

                      // start: fd.toDate(),
                      // end: ld.toDate(),
                    });
                  }
                  break;
                case groupingItems[4].picker:
                  {
                    // console.log("Год");
                    var dayOfYear = require("dayjs/plugin/dayOfYear");
                    dayjs.extend(dayOfYear);
                    const fd = newValue[0]
                      .dayOfYear(1)
                      //   // .date(1)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0);
                    const ld = newValue[1]
                      .dayOfYear(1)
                      .add(1, "year")
                      //   .date(0)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0);

                    // console.log("fd:", fd);
                    // console.log("ld:", ld);

                    setDateRange({
                      start: fd,
                      end: ld,

                      // start: fd.toDate(),
                      // end: ld.toDate(),
                    });
                  }
                  break;
              }
            }
          }}
        />
      </ConfigProvider>

      {reportType[0] === "monitoringreport" ? (
        <Input
          placeholder="№ рейса"
          size="small"
          style={{ maxWidth: "70px" }}
          onChange={setTripNumber}
          disabled={true}
        />
      ) : null}

      <Button
        disabled={checkedIds.length === 0 ? true : false}
        size="small"
        onClick={() => {
          // console.log("first day: ", dateRange.start);
          // console.log("last day: ", dateRange.end);

          loadTrips(dateRange);
        }}
      >
        Рейсы
      </Button>
    </Space>
  );
}

export default DTField;
