import { Box, Divider, Typography } from "@mui/material";
import ReactSplit, { SplitDirection } from "@devbookhq/splitter";
import { useEffect, useState } from "react";
import DTField from "./DTField";
import { useLazyQuery } from "@apollo/client";
import { GET_TRIPS } from "../GraphQLQueries/userQueries";
import dayjs from "dayjs";
import DataTable from "./DataTable";
import { Radio, Button, Cascader } from "antd";
import axios from "axios";
import fileDownload from "js-file-download";
import ObjectTree from "./ObjectTree";
import serverhost from "../config.json";
import WBDBottomPanel from "./left_panel_components/WBDBottomPanel";
import { GP } from "../Consts/Consts";
import AntdTabPanel from "./left_panel_components/AntdTabPanel";

const baseURLServ = serverhost.serverhost;

const reports = [
  {
    value: "monitoringreport",
    label: "Отчет по мониторингу",
  },
  {
    value: "generalMR",
    label: "Сводный отчет",
  },
  // {
  //   value: "test",
  //   label: "Тестовый",
  // },
];

function LeftPanel({ dataTree, userCookies }) {
  // const [
  //   getTrips,
  //   { loading: tripsLoading, error: tripsError, data: tripsList },
  // ] = useLazyQuery(GET_TRIPS);

  const [checkedIds, setCheckedIds] = useState([]);
  const [reportType, setReportType] = useState(["monitoringreport"]);
  const [groupingType, setGroupingType] = useState([GP.NONE]);
  const [tripsLoading, setTripsLoading] = useState(false);
  const [tripsList, setTripsList] = useState([]);

  useEffect(() => {
    // console.log("update groupingType: ", groupingType);
  }, []);

  const loadTrips = (dateRange) => {
    // const checkedIds2 = checkedIds.map((item) => Number(item));
    setTripsLoading(true);
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    // const now = new dayjs();
    // const timeZone = now.utcOffset() / 60;
    // console.log("Смещение: ", dayjs.tz.guess());
    // console.log("checkedIds2: ", checkedIds2);
    axios
      .get(`${baseURLServ}/wbd/trips`, {
        withCredentials: true,
        params: {
          obj_id: checkedIds.join(","),
          start_date: dateRange.start.toISOString(),
          end_date: dateRange.end.toISOString(),
          time_zone: dayjs.tz.guess(),
        },
      })
      .then((resp) => {
        // console.log(resp);
        setTripsList(resp.data);
        setTripsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // getTrips({
    //   variables: {
    //     objIds: checkedIds2,
    //     dtStart: dateRange.start,
    //     dtEnd: dateRange.end,
    //     timeZone,
    //   },
    // });
  };

  return (
    <div className="leftPane">
      <ReactSplit
        direction={SplitDirection.Vertical}
        initialSizes={[50, 50]}
        gutterClassName="gutterStyle"
        draggerClassName="draggerStyle"
        minHeights={[300, 300]}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            // position: "absolute",
          }}
        >
          <Box
            sx={{
              height: "38px",
              paddingTop: "3px",
              backgroundColor: "whitesmoke",
              flexBasis: "38px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box display={"inline-flex"} margin={"3px"}>
              <Typography
                variant="body1"
                color={"#1976d2"}
                mr={"5px"}
                fontSize={"20px"}
                fontWeight={"bold"}
              >
                MiREP Analytics
              </Typography>

              <Cascader
                size="small"
                allowClear={false}
                defaultValue={reportType}
                options={reports}
                onChange={(value) => {
                  setGroupingType([GP.NONE]);
                  setReportType(value);
                }}
                style={{ marginBottom: "2px", flexGrow: "1" }}
              />
            </Box>
            {/* <Divider variant="fullWidth" sx={{ mb: "2px" }} />
            <Radio.Group
              // defaultValue={panelSelected}
              buttonStyle="outline"
              // onChange={panelSelect}
              size="small"
            >
              <Radio.Button value="objects">Объекты</Radio.Button>
              <Radio.Button value="params">Параметры</Radio.Button>
            </Radio.Group> */}
          </Box>
          <Box
            flexGrow={1}
            overflow={"auto"}
            // sx={{ backgroundColor: "lightblue" }}
          >
            <AntdTabPanel dataTree={dataTree} setCheckedIds={setCheckedIds} />
            {/* <ObjectTree treeData={dataTree} setCheckedIds={setCheckedIds} /> */}
          </Box>
          <Box
            sx={{
              height: "31px",
              // backgroundColor: "lightcyan",
              flexBasis: "31px",
              flexShrink: "0",
              padding: "2px",
            }}
          >
            <DTField
              loadTrips={loadTrips}
              checkedIds={checkedIds}
              groupingType={groupingType}
              reportType={reportType}
              setGroupingType={setGroupingType}
            />
          </Box>
        </Box>
        <WBDBottomPanel
          // tripsError={tripsError}
          tripsList={tripsList}
          tripsLoading={tripsLoading}
          reportType={reportType}
          groupingType={groupingType}
        />
      </ReactSplit>
    </div>
  );
}

export default LeftPanel;
