// import React from "react";
import Container from "@mui/material/Container";
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setUser } from "../store/store";
import serverhost from "../config.json";

const fClick = () => {
  console.log("fClick...1");
};

const fLogin = () => {};

function LoginPage() {
  // const [connectToServer, { loading, error, data }] = useLazyQuery(loginQuery);
  const navigate = useNavigate();
  //
  const [loginStr, setLoginStr] = useState("");
  const [passwordStr, setPasswordStr] = useState("");
  const [connectionState, setConnectionState] = useState({
    isLoading: false,
    repos: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("1...", connectionState);
    if (!connectionState.isLoading && connectionState.repos !== null) {
      console.log("data...", connectionState.repos);
      navigate("../monitoringreport", { replace: false });
    }
  }, [connectionState]);

  return (
    <Container
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // flexFlow: "row wrap",
          width: "400px",
          height: "300px",
          backgroundColor: "whitesmoke",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "30px",
          borderColor: "blue",
          // padding: "30px",
          // alignContent: "center",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginRight: "30px" }}>
          <TextField
            sx={{ width: "70%", mb: "10px" }}
            label="Логин"
            variant="standard"
            value={loginStr}
            onChange={(e) => {
              setLoginStr(e.target.value);
            }}
          ></TextField>
          <TextField
            sx={{ width: "70%" }}
            label="Пароль"
            variant="standard"
            type="password"
            value={passwordStr}
            onChange={(e) => {
              setPasswordStr(e.target.value);
            }}
          ></TextField>
        </Box>
        <Box
        // sx={{
        //   display: "flex",
        //   justifyContent: "flex-end",
        //   flexDirection: "row",
        // }}
        >
          <Button
            onClick={() => {
              setConnectionState({ isLoading: true });
              const baseUrl_1 = serverhost.serverhost;
              //   console.log("Login Page host: ", serverhost.serverhost);
              //   console.log("Login Page baseurl: ", baseUrl_1);

              const instance = axios.create({
                baseURL: baseUrl_1,
                withCredentials: true,
                url: "/login",
              });
              instance
                .post(
                  "/login",
                  {
                    username: loginStr,
                    password: passwordStr,
                  },
                  {
                    // httpsAgent,
                    withCredentials: true,
                  }
                )
                .then((repos) => {
                  const allRepos = repos.data;
                  setConnectionState({ isLoading: false, repos: allRepos });
                  console.log("Repos...", repos);
                  console.log("document.cookies...", document.cookie);
                  const indexStart = document.cookie.indexOf("JSESSIONID");
                  const cookiesValue = document.cookie.slice(indexStart + 11);
                  console.log("Cookies...", cookiesValue);
                  dispatch(setUser(cookiesValue));
                })
                .catch((error) => console.log("Login error...", error));
            }}
            variant="outlined"
            sx={{ marginTop: "15px" }}
          >
            Войти
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
