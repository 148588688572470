import dayjs from "dayjs";

export const WBD_GR_REPORT_PARAMS = (wbdGRParams) => {
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  const avgWT = dayjs.utc(wbdGRParams.maxAverageWarehouseTime);
  const minTD = dayjs.utc(wbdGRParams.minTripDuration);
  const maxTD = dayjs.utc(wbdGRParams.maxTripDuration);
  const avgPT = dayjs.utc(wbdGRParams.maxAveragePointTime);

  const max_warehouse = dayjs
    .duration({
      seconds: avgWT.second(),
      minutes: avgWT.minute(),
      hours: avgWT.hour(),
    })
    .toISOString();

  const min_trip = dayjs
    .duration({
      seconds: minTD.second(),
      minutes: minTD.minute(),
      hours: minTD.hour(),
    })
    .toISOString();

  const max_trip = dayjs
    .duration({
      seconds: maxTD.second(),
      minutes: maxTD.minute(),
      hours: maxTD.hour(),
    })
    .toISOString();

  const max_point = dayjs
    .duration({
      seconds: avgPT.second(),
      minutes: avgPT.minute(),
      hours: avgPT.hour(),
    })
    .toISOString();

  return {
    deliveryType: wbdGRParams.deliveryTipe[0],
    maxAverageWarehouseTime: max_warehouse,
    minTripDuration: min_trip,
    maxTripDuration: max_trip,
    maxAveragePointTime: max_point,
  };
};
