import { Tabs } from "antd";
import WBDMRParamsPane from "./WBDMRParamsPane";
import ObjectTree from "../ObjectTree";

function AntdTabPanel({ dataTree, setCheckedIds }) {
  return (
    <Tabs
      size="small"
      centered
      style={{
        height: "100%",
      }}
      defaultActiveKey="1"
      items={[
        {
          key: 1,
          label: "Объекты",
          children: (
            <ObjectTree treeData={dataTree} setCheckedIds={setCheckedIds} />
          ),
        },
        {
          key: 2,
          label: "Параметры",
          children: <WBDMRParamsPane />,
        },
      ]}
    />
  );
}

export default AntdTabPanel;
