import { Button } from "antd";
import serverhost from "../../config.json";
import axios from "axios";
import { DOWNLOAD_REPORT_FILE } from "../../helpers/downloadReportFile";

const baseURLServ = serverhost.serverhost;

function MRButtons({ checkedTrips, setLoadingState }) {
  return (
    <div>
      <Button
        disabled={checkedTrips.length === 0 ? true : false}
        onClick={() => {
          setLoadingState(true);

          axios
            .get(`${baseURLServ}/wbd/monitoringreport`, {
              params: {
                trips_id: checkedTrips.join(","),
              },
              withCredentials: true,
              responseType: "json",
            })
            .then((resp) => {
              DOWNLOAD_REPORT_FILE(baseURLServ, resp.data, setLoadingState);
            })
            .catch((err) => console.log("Ошибка загрузки файла: ", err));
        }}
        variant="outlined"
        size="small"
      >
        Мониторинг
      </Button>
    </div>
  );
}

export default MRButtons;
