import { Box } from "@mui/material";
import React from "react";
import CheckBoxTreeView from "./CheckBoxTreeView";

function ObjectTree({ treeData, setCheckedIds }) {
  return (
    <Box
      sx={
        {
          // overflow: "auto"
        }
      }
    >
      <CheckBoxTreeView treeData={treeData} setCheckedIds={setCheckedIds} />
    </Box>
  );
}

export default ObjectTree;
