import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

function ReportMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Button
        variant="outlined"
        color="inherit"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Отчеты
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("monitoringreport", { replace: false });
            handleClose();
          }}
        >
          Отчет по мониторингу
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("generalmonitoringreport", { replace: false });
            handleClose();
          }}
        >
          Сводный отчет
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate("login", { replace: false });
            handleClose();
          }}
        >
          Login
        </MenuItem> */}
      </Menu>
    </div>
  );
}

export default ReportMenu;
