// import serverhost from "../../config.json";
import axios from "axios";
import fileDownload from "js-file-download";
import { WBD_GR_REPORT_PARAMS } from "./helpfun";

export const WBD_CREATE_LOAD_GR_REPORT = (
  baseURLServ,
  wbdGRParams,
  tripsIds,
  groupingType,
  setLoadingState
) => {
  setLoadingState(true);

  const wbdparams = WBD_GR_REPORT_PARAMS(wbdGRParams);
  // console.log("wbdparams ", wbdparams);

  axios
    .post(
      `${baseURLServ}/wbd/generalreport`,
      {
        tripsIds,
        groupingType,
        deliveryType: wbdparams.deliveryType,
        maxAverageWarehouseTime: wbdparams.maxAverageWarehouseTime,
        minTripDuration: wbdparams.minTripDuration,
        maxTripDuration: wbdparams.maxTripDuration,
        maxAveragePointTime: wbdparams.maxAveragePointTime,
      },
      {
        withCredentials: true,
        responseType: "json",
      }
    )
    .then((resp) => {
      // console.log("Resp: ", resp);
      const urlGRFile = baseURLServ + "/reports/" + resp.data;
      const grFileName = resp.data;
      axios
        .get(urlGRFile, {
          withCredentials: true,
          responseType: "blob",
        })
        .then((resp) => {
          fileDownload(resp.data, grFileName);
          setLoadingState(false);
        })
        .catch((err) => {
          alert("Ошибка загрузки файла!");
          console.log("Ошибка загрузки файла: ", err);
          setLoadingState(false);
        });
    })
    .catch((err) => {
      setLoadingState(false);
      console.log("Err: ", err);
    });
};
