import { Cascader, Button, Dropdown, Space } from "antd";
import { useLazyQuery } from "@apollo/client";
import {
  CREATE_MONITORING_REPORT,
  TEST_GENERAL,
} from "../../GraphQLQueries/userQueries";
import { useEffect, useState } from "react";
import serverhost from "../../config.json";
import axios from "axios";
import fileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import { setWBDGRParams } from "../../store/store";
import dayjs from "dayjs";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { WBD_GR_REPORT_PARAMS } from "../../helpers/helpfun";
import { WBD_CREATE_LOAD_GR_REPORT } from "../../helpers/createAndLoadWBDGR";

const baseURLServ = serverhost.serverhost;

// const handleMenuClick = (e) => {
//   console.log("click", e.key);
//   switch (e.key) {
//     case "1": {
//       console.log("click", "По выбранным рейсам");
//       WBD_CREATE_LOAD_GR_REPORT(
//         baseURLServ,
//         wbdGRParams,
//         checkedTrips,
//         groupingType[0],
//         setLoadingState
//       );

//       break;
//     }
//     case "2": {
//       console.log("click", "По всем рейсам");
//       break;
//     }
//     default: {
//       console.log("click", "Непонятный пункт");
//     }
//   }
// };

const items = [
  {
    label: "По выбранным рейсам",
    key: 1,
  },
  {
    label: "По всем рейсам",
    key: 2,
  },
];

// const menuProps = {
//   items,
//   onClick: handleMenuClick,
// };

function GeneralMRButtons({
  checkedTrips,
  setLoadingState,
  groupingType,
  allTrips,
}) {
  const wbdGRParams = useSelector((state) => state.user.wbdGRParams);
  const dispatch = useDispatch();
  // const [allTripIds, setAllTripIds] = useState(...allTrips.tripId);

  // const allTripIds = allTrips.tripId;
  // console.log("allTrips: ", ...allTrips.tripId);
  // console.log("allTrips: ", allTrips);

  if (!wbdGRParams) {
    var utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);

    if (!wbdGRParams)
      dispatch(
        setWBDGRParams({
          maxAveragePointTime: dayjs.utc("1970-01-01 00:25:00").toString(),
          maxAverageWarehouseTime: dayjs.utc("1970-01-01 01:15:00").toString(),
          minTripDuration: dayjs.utc("1970-01-01 07:00:00").toString(),
          maxTripDuration: dayjs.utc("1970-01-01 08:15:00").toString(),
          deliveryTipe: ["allDelivery"],
        })
      );
  }

  const handleMenuClick = (e) => {
    // console.log("click", e.key);
    switch (e.key) {
      case "1": {
        console.log("allTrips: ", allTrips);
        console.log("checkedTrips: ", checkedTrips);

        if (checkedTrips.length === 0)
          alert("Не выбран ни один рейс для формирования отчета!");
        else {
          console.log("click", "По выбранным рейсам");
          WBD_CREATE_LOAD_GR_REPORT(
            baseURLServ,
            wbdGRParams,
            checkedTrips,
            groupingType[0],
            setLoadingState
          );
        }

        break;
      }
      case "2": {
        console.log("allTrips: ", allTrips);
        console.log("checkedTrips: ", checkedTrips);
        WBD_CREATE_LOAD_GR_REPORT(
          baseURLServ,
          wbdGRParams,
          allTrips,
          groupingType[0],
          setLoadingState
        );
        break;
      }
      default: {
        console.log("click", "Непонятный пункт");
      }
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {}, []);

  return (
    <div>
      {/* <Button
        size="small"
        disabled={checkedTrips.length === 0 ? true : false}
        onClick={() => {
          WBD_CREATE_LOAD_GR_REPORT(
            baseURLServ,
            wbdGRParams,
            checkedTrips,
            groupingType[0],
            setLoadingState
          );
          // setLoadingState(true);

          // const wbdparams = WBD_GR_REPORT_PARAMS(wbdGRParams);
          // // console.log("wbdparams ", wbdparams);

          // axios
          //   .post(
          //     `${baseURLServ}/wbd/generalreport`,
          //     {
          //       tripsIds: checkedTrips,
          //       groupingType: groupingType[0],
          //       deliveryType: wbdparams.deliveryType,
          //       maxAverageWarehouseTime: wbdparams.maxAverageWarehouseTime,
          //       minTripDuration: wbdparams.minTripDuration,
          //       maxTripDuration: wbdparams.maxTripDuration,
          //       maxAveragePointTime: wbdparams.maxAveragePointTime,
          //     },
          //     {
          //       withCredentials: true,
          //       responseType: "json",
          //     }
          //   )
          //   .then((resp) => {
          //     // console.log("Resp: ", resp);
          //     const urlGRFile = baseURLServ + "/reports/" + resp.data;
          //     const grFileName = resp.data;
          //     axios
          //       .get(urlGRFile, {
          //         withCredentials: true,
          //         responseType: "blob",
          //       })
          //       .then((resp) => {
          //         fileDownload(resp.data, grFileName);
          //         setLoadingState(false);
          //       })
          //       .catch((err) => {
          //         alert("Ошибка загрузки файла!");
          //         console.log("Ошибка загрузки файла: ", err);
          //         setLoadingState(false);
          //       });
          //   })
          //   .catch((err) => {
          //     setLoadingState(false);
          //     console.log("Err: ", err);
          //   });
        }}
      >
        Сводный
      </Button> */}
      <Dropdown menu={menuProps}>
        <Button size="small">
          <Space>
            Сводный
            <ExpandMoreOutlinedIcon />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
}

export default GeneralMRButtons;
