import "../index.css";
import { Box, Button } from "@mui/material";
import ReactSplit, { SplitDirection } from "@devbookhq/splitter";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DTField from "../components/DTField";
import LeftPanel from "../components/LeftPanel";
import serverhost from "../config.json";

const transformTreeItem = (item) => {
  const newItem = {
    value: item.real_id,
    label: item.name,
  };
  if (item.leaf) return newItem;
  else {
    newItem.children = item.children.map((item) => transformTreeItem(item));
    return newItem;
  }
};

function MonitoringReport() {
  const navigate = useNavigate();

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    dataTree: null,
  });

  const userCookies = useSelector((state) => state.user.cookies);
  console.log("userCookies: ", userCookies);
  // const checkedId = [];

  useEffect(() => {
    setLoadingState({ isLoading: true });
    const baseUrlServ = serverhost.serverhost;
    // console.log("MR serverhost:", serverhost.serverhost);

    axios
      .get(`${baseUrlServ}/gettree`, {
        withCredentials: true,
        headers: { JSESSIONID: userCookies },
      })
      .then((response) => {
        console.log("Status: ", response.data.status);
        const tmpTreeData = response.data;
        console.log("TreeData: ", tmpTreeData);
        const treeData = tmpTreeData.map((item) => transformTreeItem(item));
        console.log("TreeData after transform: ", treeData);
        setLoadingState({ isLoading: false, dataTree: treeData });
      })
      .catch((error) => {
        console.log("Loading data error:", error);
        if (
          error.response.data.status === 403 ||
          error.response.data.status === 500
        ) {
          alert(
            "Время сессии истекло. Вы будете перенаправлены на страницу авторизации!"
          );
          setLoadingState({ isLoading: false, dataTree: null });
          navigate("../login", { replace: false });
        }
      });
  }, []);

  return (
    <Box
      sx={{
        marginTop: "2px",
        width: 1,
        height: "100%",
        backgroundColor: "whitesmoke",
      }}
    >
      <ReactSplit
        direction={SplitDirection.Horizontal}
        initialSizes={[20, 80]}
        gutterClassName="gutterStyle"
        draggerClassName="draggerStyle"
        minWidths={[400, 500]}
      >
        <LeftPanel dataTree={loadingState.dataTree} userCookies={userCookies} />
        <div className="leftPane">Tile 2</div>
      </ReactSplit>
    </Box>
  );
}

export default MonitoringReport;
