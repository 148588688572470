import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const userSlice = createSlice({
  name: "@@user",
  initialState: {
    user: {
      username: "",
      cookies: "",
    },
    wbdMRParams: {
      minStopTime: 3,
      shiftToStart: -2,
      shiftToEnd: 4,
    },
    wbdGRParams: {
      maxAveragePointTime: dayjs.utc("1970-01-01 00:25:00").toString(),
      maxAverageWarehouseTime: dayjs.utc("1970-01-01 01:15:00").toString(),
      minTripDuration: dayjs.utc("1970-01-01 07:00:00").toString(),
      maxTripDuration: dayjs.utc("1970-01-01 08:15:00").toString(),
      deliveryTipe: ["allDelivery"],
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.cookies = action.payload;
    },
    setWBDMRPapams: (state, action) => {
      state.wbdMRParams = action.payload;
    },
    setWBDGRParams: (state, action) => {
      state.wbdGRParams = action.payload;
    },
  },
});

const rootReducer = combineReducers({
  user: userSlice.reducer,
});

const persistConfig = {
  key: "user",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const { setUser, setWBDGRParams, setWBDMRPapams } = userSlice.actions;

export const persistor = persistStore(store);
