import React from "react";
import CheckBoxTreeView from "../components/CheckBoxTreeView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";

function GeneralMonitoringReport() {
  return (
    <>
      <h1>General Monitoring Report</h1>
      {/* <CheckBoxTreeView /> */}
    </>
  );
}

export default GeneralMonitoringReport;
