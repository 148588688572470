import { AppBar, Toolbar, Typography, createTheme } from "@mui/material";
import React, { Component } from "react";
import ReportMenu from "./ReportMenu";
import { deepOrange, red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: deepOrange[100],
    },
    secondary: {
      main: red[300],
    },
  },
});

function Header() {
  const navigate = useNavigate();

  return (
    <AppBar position="static" sx={{ display: "flex" }}>
      <Toolbar variant="dense">
        {/* <MainMenu /> */}
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ mr: 2, cursor: "pointer" }}
          onClick={() => navigate("/", { replace: false })}
        >
          MiREP Analytics
        </Typography>
        <ReportMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
