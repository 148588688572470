import { Box } from "@mui/material";
import { Button, Cascader } from "antd";
import React, { useEffect } from "react";
import GeneralMRButtons from "./GeneralMRButtons";
import MRButtons from "./MRButtons";
import TestReportsButtons from "./TestReportsButtons";

function ReportsLoadButtons({
  reportType,
  checkedTrips,
  setLoadingState,
  groupingType,
  allTrips,
}) {
  useEffect(() => {
    // console.log("ReportsLoadButtons_groupingType: ", groupingType);
  }, []);
  return (
    <Box>
      {reportType[0] === "monitoringreport" ? (
        <MRButtons
          checkedTrips={checkedTrips}
          setLoadingState={setLoadingState}
        />
      ) : null}
      {reportType[0] === "generalMR" ? (
        <GeneralMRButtons
          checkedTrips={checkedTrips}
          setLoadingState={setLoadingState}
          groupingType={groupingType}
          allTrips={allTrips}
        />
      ) : null}
      {reportType[0] === "test" ? (
        <TestReportsButtons
          checkedTrips={checkedTrips}
          setLoadingState={setLoadingState}
          // groupingType={groupingType}
        />
      ) : null}
    </Box>
  );
}

export default ReportsLoadButtons;
