import React from "react";
// import Header from "../components/Header";
import { Outlet } from "react-router-dom";
// import Ant_Header from "../components/Ant_Header";

function MainLayout() {
  return (
    <>
      {/* <Header /> */}
      {/* <Ant_Header /> */}
      <Outlet />
    </>
  );
}

export default MainLayout;
